<template>
  <div>
    <p>登录跳转中，请稍后……</p>
  </div>
</template>

<script>
import {getToken} from "@/util/auth";

export default {
name: "SSO",
  mounted() {
    let type = this.$route.params.type
    this.$API.user.getUserInfo().then(res=>{
       if (res.errno == 0){
         if (type == 1){
           let redirect = this.$route.query.ssourl
           let url = redirect+'?token='+getToken()
           console.log(url)
           window.location = url
         }else if(type == 2){
           this.ssoChunshu()
         }else{
           alert('模式识别失败')
           this.$router.push('/')
         }
       }else{
         alert('请登录')
         this.$router.push('/')
       }
    })



  },
  methods:{
    ssoChunshu(){
      this.$API.actionUrl.ssoChunshu().then(res=>{
        console.log(res)
        if (res.errno == 0){
          window.location = res.data.jumpUrl
        }
      }).catch(e=>{
        alert('服务出错，请联系技术支持')
        this.$router.push('/')
      })
    },
  }
}
</script>

<style scoped>

</style>
